import React, { Component } from "react";
import { connect } from "react-redux";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBCardBody,
  MDBInputGroup,
  MDBModal,
  MDBModalBody,
  MDBModalHeader
} from "mdbreact";
import Accordion from "react-bootstrap/Accordion";
import { BarChart as ChartIcon } from "@material-ui/icons";
import { Typography, Box } from "@material-ui/core";

import { Card } from "react-bootstrap";
import firebase from "../../utils/firebase/firebase.utils";
import { Link, withRouter } from "react-router-dom";
import { nanoid } from "nanoid";

import { updateUser } from "../../redux/user/user.actions";
import { fetchDesigns } from "../../redux/designImages/designImages.actions";

import "./contact-form.css";

import StyledDemo from "../../components/images/index";

import API2 from "../../components/generateQr/generateQR";
import API from "../../components/generate-card/contact";
import LANDING from "../../pages/profile/profile";
import GRAPHAPI from "./graph";
// import { saveAs, encodeBase64 } from "@progress/kendo-file-saver";

import ReactQr from "react-awesome-qr";
import MyLogo from "../../assets/logo5.png";
import RangeWater from "../../assets/rangewater.png";
import Placeholder from "./assets/images/placeholder.jpeg"

class FormPage extends Component {
  state = {
    modal: false,
    modal2: false,
    firstName: "",
    lastName: "",
    company: "",
    title: "",
    cellPhone: "",
    workPhone: "",
    homePhone: "",
    persEmail: "",
    workEmail: "",
    link: "",
    workLink: "",
    homeStreet: "",
    homeCity: "",
    homeState: "",
    homeZip: "",
    workStreet: "",
    workCity: "",
    workState: "",
    workZip: "",
    birthday: "",
    socialType1: "",
    socialUserName1: "",
    socialLink1: "",
    socialType2: "",
    socialUserName2: "",
    socialLink2: "",
    socialType3: "",
    socialUserName3: "",
    socialLink3: "",
    socialType4: "",
    socialUserName4: "",
    socialLink4: "",
    socialType5: "",
    socialUserName5: "",
    socialLink5: "",
    socialType6: "",
    socialUserName6: "",
    socialLink6: "",
    cardHasUrl: false,
    message: "",
    imgLink: "",
    youtubeLink: "",
    html: "",
    directLink:`https://firebasestorage.googleapis.com/v0/b/mytap-2d6fa.appspot.com/o/${this.props.userId}.html?alt=media&token=f9996a6f-9ccc-4469-aa6a-64ddd296cbce`,
    customDirectLink: "",
    generateQrLink: "",
    profile: "1",
    inputFocus: false,
    totalClicks: "",
    clickDates: [],
    clickTimes: [],
    modal3: false,
    saveBtn: "PREVIEW!"
  };

  // Redirect to fulfillment page when applicable
  componentDidUpdate() {
    if (this.props.currentUser.role === "fulfillment") {
      this.props.history.push("/fulfillment");
    }
    if (this.props.currentUser.email === "rangewater@gmail.com") {
      this.props.history.push("/rangewater-order");
    }
    if (this.props.currentUser.email === "marmenta@galleryresidential.com") {
      this.props.history.push("/gallery-residential");
    }
    if (this.props.currentUser.email === "ksarvis@columbiares.com") {
      this.props.history.push("/columbia-residential-order");
    }
    if (this.props.currentUser.isEnterprise) {
      this.props.history.push("/enterprise");
    }
  }

  componentDidMount() {
    this.props.fetchDesigns();

    setTimeout(() => {
      this.getClicks();
    }, 1000);
  
    setTimeout(() => {
      if (this.props.formData) {
        const { profile, formData, formData2, formData3,formData4 } = this.props;
        let parsedFormData;
        
        switch (profile) {
          case '1':
            parsedFormData = JSON.parse(formData);
            break;
          case '2':
            parsedFormData = JSON.parse(formData2);
            break;
          case '3':
            parsedFormData = JSON.parse(formData3);
            break;
          case '4':
            parsedFormData = JSON.parse(formData4);
            break;
          default:
            parsedFormData = JSON.parse(formData);
            break;
        }
        // const formData = this.props.profile === '1' ? JSON.parse(this.props.formData): this.props.profile === '2' ? JSON.parse(this.props.formData2): this.props.profile === '3'? JSON.parse(this.props.formData3): JSON.parse(this.props.formData);
        this.setState({
          firstName: parsedFormData.firstName,
          lastName: parsedFormData.lastName,
          company: parsedFormData.company,
          title: parsedFormData.title,
          cellPhone: parsedFormData.cellPhone,
          workPhone: parsedFormData.workPhone,
          homePhone: parsedFormData.homePhone,
          persEmail: parsedFormData.persEmail,
          workEmail: parsedFormData.workEmail,
          link: parsedFormData.link,
          workLink: parsedFormData.workLink,
          homeStreet: parsedFormData.homeStreet,
          homeCity: parsedFormData.homeCity,
          homeState: parsedFormData.homeState,
          homeZip: parsedFormData.homeZip,
          workStreet: parsedFormData.workStreet,
          workCity: parsedFormData.workCity,
          workState: parsedFormData.workState,
          workZip: parsedFormData.workZip,
          birthday: parsedFormData.birthday,
          socialType1: parsedFormData.socialType1,
          socialUserName1: parsedFormData.socialUserName1,
          socialLink1: parsedFormData.socialLink1,
          socialType2: parsedFormData.socialType2,
          socialUserName2: parsedFormData.socialUserName2,
          socialLink2: parsedFormData.socialLink2,
          socialType3: parsedFormData.socialType3,
          socialUserName3: parsedFormData.socialUserName3,
          socialLink3: parsedFormData.socialLink3,
          socialType4: parsedFormData.socialType4,
          socialUserName4: parsedFormData.socialUserName4,
          socialLink4: parsedFormData.socialLink4,
          socialType5: parsedFormData.socialType5,
          socialUserName5: parsedFormData.socialUserName5,
          socialLink5: parsedFormData.socialLink5,
          socialType6: parsedFormData.socialType6,
          socialUserName6: parsedFormData.socialUserName6,
          socialLink6: parsedFormData.socialLink6,
          html: parsedFormData.html,
          imgLink: parsedFormData.imgLink,
          youtubeLink: parsedFormData.youtubeLink,
          message: parsedFormData.message,
          directLink: parsedFormData.directLink,
          customDirectLink: parsedFormData.customDirectLink,
          generateQrLink: parsedFormData.generateQrLink,
          profile: parsedFormData.profile

        });
      } else {
        this.setState({
          firstName: "",
          lastName: "",
          company: "",
          title: "",
          cellPhone: "",
          workPhone: "",
          homePhone: "",
          persEmail: "",
          workEmail: "",
          link: "",
          workLink: "",
          homeStreet: "",
          homeCity: "",
          homeState: "",
          homeZip: "",
          workStreet: "",
          workCity: "",
          workState: "",
          workZip: "",
          birthday: "",
          socialType1: "",
          socialUserName1: "",
          socialLink1: "",
          socialType2: "",
          socialUserName2: "",
          socialLink2: "",
          socialType3: "",
          socialUserName3: "",
          socialLink3: "",
          socialType4: "",
          socialUserName4: "",
          socialLink4: "",
          socialType5: "",
          socialUserName5: "",
          socialLink5: "",
          socialType6: "",
          socialUserName6: "",
          socialLink6: "",
          message: "",
          imgLink: "",
          youtubeLink: "",
          html: "",
          directLink:  `https://firebasestorage.googleapis.com/v0/b/mytap-2d6fa.appspot.com/o/${this.props.userId}.html?alt=media&token=f9996a6f-9ccc-4469-aa6a-64ddd296cbce`,
          customDirectLink: "",
          generateQrLink: "",
          profile: "1"
        });
      }
      if (this.props.currentUser && !this.props.formData2) {
        var db = firebase.firestore();
        db.collection("users")
          .doc(`${this.props.userId}`)
          .update({
            formData: `${JSON.stringify(this.state)}`,
            formData2: `${JSON.stringify(this.state)}`,
            formData3: `${JSON.stringify(this.state)}`,
            formData4: `${JSON.stringify(this.state)}`,

            profile: '1',
            uid: `${this.props.userId}`
          })
          .then(() => {
            this.setState({directLink: `https://firebasestorage.googleapis.com/v0/b/mytap-2d6fa.appspot.com/o/${this.props.userId}.html?alt=media&token=f9996a6f-9ccc-4469-aa6a-64ddd296cbce`})
            this.setState({ 
              totalClicks: 0,
              profile:1                  
            });
            
            console.log("Document successfully written!");

            API.generateCard();

            var storageRef = firebase.storage().ref();

            const encodedString = new Buffer(
              localStorage.getItem("vCard")
            ).toString("base64");
            storageRef
              .child(`${this.props.userId}.vcf`)
              .putString(encodedString, "base64")
              .then((snapshot) => {
                // alert("Sent to MyTap Card");
                LANDING.updateProfile();
                // this.directLink();

                storageRef
                  .child(`${this.props.userId}.vcf`)
                  .getDownloadURL()
                  .then((url) => {
                    db.collection("users")
                      .doc(`${this.props.userId}`)
                      .update({
                        cardUrl: `${url}`
                      })
                      .then(() => {
                        console.log("QrUrl successfully written!");
                        // this.setURL();
                        if (!this.props.shortUrl) {
                          setTimeout(() => {
                            this.createShortLink();
                          }, 1000);
                        }
                        setTimeout(() => {
                          API2.generateQR();
                        }, 1500);
                      })
                      .catch(function (error) {
                        console.error("Error writing document: ", error);
                      });
                  });
              });
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      }
    }, 1000);
  }

  createShortLink() {
    var db = firebase.firestore();
    const link = {
      name: "relink",
      longURL:
        this.props.landingUrl.includes("http://") ||
        this.props.landingUrl.includes("https://")
          ? this.props.landingUrl
          : `http://${this.props.landingUrl}`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      shortCode: nanoid(6),
      totalClicks: 0,
      clickDates: [],
      profile: 1
    };

    db.collection("users")
      .doc(`${this.props.userId}`)
      .collection("links")
      .add(link)
      .then(() => {
        db.collection("users")
          .doc(`${this.props.userId}`)
          .update({
            shortUrl: `https://www.mytap.net/rd/${link.shortCode}`
          });
      });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  };

  toggle3 = () => {
    GRAPHAPI.graph(this.state.clickDates, this.state.clickTimes);
    setTimeout(() => {
      this.setState({
        modal3: !this.state.modal3
      });
    }, 450);
  };

  inputFocusHandler = (e) => {
    this.setState({
      inputFocus: !this.state.inputFocus
    });
    this.handleSubmit(e)
  };

  setURL = () => {
    this.setState({
      cardHasUrl: true
    });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.updateShortLink()
    // this.handleSubmit(e)
  };

  resetName = (e) => {
    this.setState({
      birthday: ""
    });
    this.handleSubmit(e)
  };

  preview = () => {
  
    const formattedURL = url => {
      if (url.includes("http://") || url.includes("https://")) {
          return url;
      } else {
          return `https://${url}`;
      }
    };

    const longURL = this.state.directLink === "1" ? 
      formattedURL(this.state.customDirectLink) : formattedURL(this.state.directLink);

    if (this.state.directLink.split('?')[0] === this.props.landingUrl.split('?')[0]){
      this.toggle2()
    }
    else{
        window.open(longURL,'_blank')
    }
      
  };


  directChangeHandler(event) {
    // alert(event.target.value)
    this.setState({ directLink: event.target.value });
    this.updateShortLink()
  }

  async updateShortLink() {
    const db = firebase.firestore();
    const longCode = this.props.shortUrl;
    const shortCode = longCode.substring(longCode.lastIndexOf("/") + 1);
    const linkDoc = await db.collection("links").doc(shortCode).get();
    const { linkID, userId } = linkDoc.data();

    const formattedURL = url => {
        if (url.includes("http://") || url.includes("https://")) {
            return url;
        } else {
            return `https://${url}`;
        }
    };

    const longURL = this.state.directLink === "1" ? 
        formattedURL(this.state.customDirectLink) : formattedURL(this.state.directLink);

    db.collection("links").doc(shortCode).update({ longURL })
        .then(() => {
            db.collection("users").doc(userId).collection("links").doc(linkID).update({ longURL });
        });
}


  getClicks() {
    firebase
      .firestore()
      .collection(`users/${this.props.userId}/links`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log(doc.id, " => ", doc.data());
          const { profile } = this.props
          const Clicks = profile === "1" ? `totalClicks` :`totalClicks${profile}`;
          const Dates = profile === "1" ? `clickDates` :`clickDates${profile}`;

          if (doc.data()[Dates]){
            const clicks = doc.data()[Clicks];
            const clickDates = doc.data()[Dates];
            this.setState({ totalClicks: clicks });
  
            const dates = clickDates.map((date) =>
              new Date(date.seconds * 1000).setHours(0, 0, 0, 0)
            );
            const clickTimes = clickDates.map((date) =>
              new Date(date * 1000).toLocaleTimeString()
            );
            this.setState({ clickDates: dates });
            this.setState({ clickTimes: clickTimes });
          }
          else{
            this.setState({ totalClicks: 0 });
        }
         

        });
      });
  }

  stateChangeHandler() {
    if (this.props.formData) {
      const { profile, formData, formData2, formData3,formData4 } = this.props;
        let parsedFormData;
        
        switch (profile) {
          case '1':
            parsedFormData = JSON.parse(formData);
            break;
          case '2':
            parsedFormData = JSON.parse(formData2);
            break;
          case '3':
            parsedFormData = JSON.parse(formData3);
            break;
          case '4':
            parsedFormData = JSON.parse(formData4);
            break;
          default:
            parsedFormData = JSON.parse(formData);
            break;
        }
        this.setState({
          firstName: parsedFormData.firstName,
          lastName: parsedFormData.lastName,
          company: parsedFormData.company,
          title: parsedFormData.title,
          cellPhone: parsedFormData.cellPhone,
          workPhone: parsedFormData.workPhone,
          homePhone: parsedFormData.homePhone,
          persEmail: parsedFormData.persEmail,
          workEmail: parsedFormData.workEmail,
          link: parsedFormData.link,
          workLink: parsedFormData.workLink,
          homeStreet: parsedFormData.homeStreet,
          homeCity: parsedFormData.homeCity,
          homeState: parsedFormData.homeState,
          homeZip: parsedFormData.homeZip,
          workStreet: parsedFormData.workStreet,
          workCity: parsedFormData.workCity,
          workState: parsedFormData.workState,
          workZip: parsedFormData.workZip,
          birthday: parsedFormData.birthday,
          socialType1: parsedFormData.socialType1,
          socialUserName1: parsedFormData.socialUserName1,
          socialLink1: parsedFormData.socialLink1,
          socialType2: parsedFormData.socialType2,
          socialUserName2: parsedFormData.socialUserName2,
          socialLink2: parsedFormData.socialLink2,
          socialType3: parsedFormData.socialType3,
          socialUserName3: parsedFormData.socialUserName3,
          socialLink3: parsedFormData.socialLink3,
          socialType4: parsedFormData.socialType4,
          socialUserName4: parsedFormData.socialUserName4,
          socialLink4: parsedFormData.socialLink4,
          socialType5: parsedFormData.socialType5,
          socialUserName5: parsedFormData.socialUserName5,
          socialLink5: parsedFormData.socialLink5,
          socialType6: parsedFormData.socialType6,
          socialUserName6: parsedFormData.socialUserName6,
          socialLink6: parsedFormData.socialLink6,
          html: parsedFormData.html,
          imgLink: parsedFormData.imgLink,
          youtubeLink: parsedFormData.youtubeLink,
          message: parsedFormData.message,
          directLink: parsedFormData.directLink,
          customDirectLink: parsedFormData.customDirectLink,
          generateQrLink: parsedFormData.generateQrLink,

        });
      }
  }

  async updateLinksProfile() {
    var db = firebase.firestore();
    const longCode = this.props.shortUrl
    const shortCode = longCode.substring(longCode.lastIndexOf("/") + 1);
    const linkDoc = await db.collection("links").doc(shortCode).get();
    const { linkID } = linkDoc.data();
    db.collection("users")
    .doc(`${this.props.userId}`)
    .collection("links")
    .doc(linkID)
    .update({
      profile: this.props.profile,
    })
  }


  profileChangeHandler(event) {
    var db = firebase.firestore();
    this.setState({ profile: event.target.value });
    db.collection("users")
    .doc(`${this.props.userId}`)
    .update({
      profile: event.target.value,
    })
    .then(()=>{
      this.updateLinksProfile()
    })
    .then(() => {
      this.stateChangeHandler()
    })
    .then(()=>{
      this.handleSubmit(event)
      this.updateShortLink()
    })
    .then(()=>{
      this.getClicks()
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!this.props.shortUrl) {
      this.createShortLink();
      setTimeout(() => {
        API2.generateQR();
      }, 1000);
    }
    
    const db = firebase.firestore();
    const { profile} = this.props;
    const formDataKey = profile === "1" ? `formData` :`formData${profile}`;
    try {
      await db.collection("users").doc(this.props.userId).update({
        [formDataKey]: JSON.stringify(this.state),
        uid: this.props.userId
      });
  
      console.log("Document successfully written!");
  
      API.generateCard();
  
      const storageRef = firebase.storage().ref();
      const encodedString = Buffer.from(localStorage.getItem("vCard")).toString("base64");
      await storageRef.child(`${this.props.userId}.vcf`).putString(encodedString, "base64");
  
      LANDING.updateProfile();
      // this.directLink();
  
      if (!this.props.QrImg) {
        API2.generateQR();
      }
  
      // alert("Sent to MyTap Card");
      this.setState({ saveBtn: "SAVED!" });
      setTimeout(() => {
        this.setState({ saveBtn: "PREVIEW!" });
      }, 3000);
      
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };


  render() {
    if (!this.props.formData) {
      return <div>.</div>; 
    }
    const { profile} = this.props;
    const croppedImgKey = profile === "1" ? `croppedImg` :`croppedImg${profile}`;
    
    const parsedFormData = JSON.parse(this.props.formData);
    const parsedFormData2 = this.props.formData2 ? JSON.parse(this.props.formData2): ''
    const parsedFormData3 = this.props.formData3 ? JSON.parse(this.props.formData3): ''
    const parsedFormData4 = this.props.formData4 ? JSON.parse(this.props.formData4): ''

    var db = firebase.firestore();
    // var storageRef = firebase.storage().ref();
    return (
      <MDBContainer size="xl">
        <MDBRow center className="force-negative-margins">
          <MDBCol md="8" id={"nopad"}>
            <MDBCardBody>
            <div style={{ width: '37%'}}>
                    <select
                      value={this.props.profile}
                      className="browser-default custom-select"
                      onChange={this.profileChangeHandler.bind(this)}
                    >
                      <option value={"1"}>{parsedFormData.company.length > 3 ? parsedFormData.company : 'First Profile'}</option>

                      <option value={"2"}>{parsedFormData2 && parsedFormData2.company.length > 3 ? parsedFormData2.company : 'Second Profile'}</option>

                      <option value={"3"}>{parsedFormData3 && parsedFormData3.company.length > 3 ? parsedFormData3.company : 'Third Profile'}</option>

                      <option value={"4"}>{parsedFormData4 && parsedFormData4.company.length > 3 ? parsedFormData4.company : 'Fourth Profile'}</option>


                    </select>
                  </div>
              <form id="contact-form" onSubmit={this.handleSubmit.bind(this)}>
                <MDBRow center>
                  {/* <MDBCol size="0" md="3" sm="0" xs="0"></MDBCol> */}
                  <MDBCol
                    // size="12"
                    style={{ maxWidth: "250px" }}
                    className="mb-1 text-center"
                  >
                    <div className="profile-container">
                    <img
                      src={this.props[croppedImgKey]|| Placeholder}
                      className="img-fluid z-depth-1 rounded-circle mb-1"
                      alt=""
                    />
                    <div
                      className="upload-button"
                      onClick={this.toggle}
                    >
                      <MDBIcon icon="camera" size='lg' />
                    </div>
                    </div>

                    <MDBContainer>
                      {/* MODAL */}
                      <MDBModal
                        isOpen={this.state.modal}
                        toggle={this.toggle}
                        size="fluid"
                      >
                        <MDBModalBody>
                          <StyledDemo />
                        </MDBModalBody>
                      </MDBModal>
                    </MDBContainer>
                  </MDBCol>
                </MDBRow>

               
                <div className="container">
                    <div className="select-container">
                      <select
                            onBlur={this.handleSubmit.bind(this)}
                            value={this.state.directLink}
                            className="browser-default custom-select"
                            onChange={this.directChangeHandler.bind(this)}
                            style={{ width: 'auto'}}
                          >
                            <option
                              value={this.props.landingUrl}
                            >
                              Landing Page (default)
                            </option>
                            

                            <option value={this.props.cardUrl}>VCF (Contact Card)</option>

                            {this.state.socialType1 ? (
                              <option value={this.state.socialLink1}>
                                {this.state.socialType1}
                              </option>
                            ) : null}

                            {this.state.socialType2 ? (
                              <option value={this.state.socialLink2}>
                                {this.state.socialType2}
                              </option>
                            ) : null}

                            {this.state.socialType3 ? (
                              <option value={this.state.socialLink3}>
                                {this.state.socialType3}
                              </option>
                            ) : null}

                            {this.state.socialType4 ? (
                              <option value={this.state.socialLink4}>
                                {this.state.socialType4}
                              </option>
                            ) : null}

                            {this.state.socialType5 ? (
                              <option value={this.state.socialLink5}>
                                {this.state.socialType5}
                              </option>
                            ) : null}

                            {this.state.socialType6 ? (
                              <option value={this.state.socialLink6}>
                                {this.state.socialType6}
                              </option>
                            ) : null}

                            <option value="1">Custom</option>
                          </select>
                  </div>
                          


                <Box padding={1} paddingBottom={0}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Typography>{this.state.totalClicks}</Typography>
                        <ChartIcon
                            className="click-box"
                            onClick={this.toggle3}
                        />
                    </Box>
                    <Typography variant="overline">Total Clicks</Typography>
                    <MDBModal
                        size="lg"
                        isOpen={this.state.modal3}
                        toggle={this.toggle3}
                    >
                        <MDBModalBody
                            style={{ height: "60vh", padding: "0" }}
                        >
                            <iframe
                                name="iframe1"
                                title="frame"
                                style={{
                                    border: "none",
                                    width: "100%",
                                    height: "100%"
                                }}
                                src={`https://firebasestorage.googleapis.com/v0/b/mytap-2d6fa.appspot.com/o/${this.props.userId}-graph.html?alt=media&token=c3efec8d-69cc-45b6-9d96-0d0bee85f58b`}
                            ></iframe>
                        </MDBModalBody>
                    </MDBModal>
                </Box>
            </div>
                        {this.state.directLink === "1" ? (
                            <MDBInput
                              style={{ marginTop: "-20pt"}}
                              onBlur={this.handleSubmit.bind(this)}
                              label="Direct Link"
                              name="customDirectLink"
                              icon="link"
                              group
                              value={this.state.customDirectLink}
                              onChange={this.onChange.bind(this)}
                            />
                          ) : null}





                
                <div className="black-text">
                  <Accordion defaultActiveKey="1">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        NAME
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="First Name"
                            name="firstName"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.firstName}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Last Name"
                            name="lastName"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.lastName}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Company"
                            name="company"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.company}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Title"
                            name="title"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.title}
                            onChange={this.onChange.bind(this)}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="1">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        PHONE NUMBERS
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Cell Phone"
                            name="cellPhone"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.cellPhone}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Work Phone"
                            name="workPhone"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.workPhone}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Home Phone"
                            name="homePhone"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.homePhone}
                            onChange={this.onChange.bind(this)}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="1">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        EMAIL ADDRESSES
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Email"
                            name="persEmail"
                            group
                            type="email"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.persEmail}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Work Email"
                            name="workEmail"
                            group
                            type="email"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.workEmail}
                            onChange={this.onChange.bind(this)}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="1">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        WEBSITE LINKS
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Link"
                            name="link"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.link}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Work Link"
                            name="workLink"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.workLink}
                            onChange={this.onChange.bind(this)}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="1">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        ADDRESSES
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <br /> HOME ADDRESS
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Street"
                            name="homeStreet"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.homeStreet}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="City"
                            name="homeCity"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.homeCity}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="State"
                            name="homeState"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.homeState}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Zip"
                            name="homeZip"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.homeZip}
                            onChange={this.onChange.bind(this)}
                          />
                          <br /> WORK ADDRESS
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Street"
                            name="workStreet"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.workStreet}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="City"
                            name="workCity"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.workCity}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="State"
                            name="workState"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.workState}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Zip"
                            name="workZip"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.workZip}
                            onChange={this.onChange.bind(this)}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="1">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        BIRTHDAY
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <MDBInput
                            onBlur={this.handleSubmit.bind(this)}
                            label="Birthday"
                            name="birthday"
                            group
                            type="date"
                            validate
                            error="wrong"
                            success="right"
                            value={this.state.birthday}
                            onChange={this.onChange.bind(this)}
                          />
                          <MDBBtn
                            className="btn unique-color-dark white-text pushBtn"
                            color="unique-color-dark"
                            onClick={this.resetName}
                          >
                            <MDBIcon icon="eraser" /> Clear
                          </MDBBtn>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <Accordion defaultActiveKey="1">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        SOCIAL MEDIA LINKS
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <MDBRow id="social-titles" className="text-center">
                            <MDBCol>TYPE</MDBCol>
                            <MDBCol>DISPLAY NAME</MDBCol>
                            <MDBCol>LINK</MDBCol>
                          </MDBRow>

                          <MDBInputGroup
                            inputs={
                              <>
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialType1"
                                  // className="expandable"
                                  type="text"
                                  hint='"Facebook"'
                                  value={this.state.socialType1}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialUserName1"
                                  // className="expandable"
                                  type="text"
                                  hint='"Your Name"'
                                  value={this.state.socialUserName1}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialLink1"
                                  // className="expandable"
                                  type="text"
                                  hint='"https://..."'
                                  value={this.state.socialLink1}
                                  onChange={this.onChange.bind(this)}
                                />
                              </>
                            }
                          />
                          <MDBInputGroup
                            inputs={
                              <>
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialType2"
                                  type="text"
                                  hint='"Type"'
                                  value={this.state.socialType2}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialUserName2"
                                  type="text"
                                  hint='"@Your Name"'
                                  value={this.state.socialUserName2}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialLink2"
                                  type="text"
                                  hint='"https://..."'
                                  value={this.state.socialLink2}
                                  onChange={this.onChange.bind(this)}
                                />
                              </>
                            }
                          />
                          <MDBInputGroup
                            inputs={
                              <>
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialType3"
                                  type="text"
                                  hint='"Type"'
                                  value={this.state.socialType3}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialUserName3"
                                  type="text"
                                  hint='"$Your Name"'
                                  value={this.state.socialUserName3}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialLink3"
                                  type="text"
                                  hint='"https://..."'
                                  value={this.state.socialLink3}
                                  onChange={this.onChange.bind(this)}
                                />
                              </>
                            }
                          />
                          <MDBInputGroup
                            inputs={
                              <>
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialType4"
                                  type="text"
                                  value={this.state.socialType4}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialUserName4"
                                  type="text"
                                  value={this.state.socialUserName4}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialLink4"
                                  type="text"
                                  value={this.state.socialLink4}
                                  onChange={this.onChange.bind(this)}
                                />
                              </>
                            }
                          />
                          <MDBInputGroup
                            inputs={
                              <>
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialType5"
                                  type="text"
                                  value={this.state.socialType5}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialUserName5"
                                  type="text"
                                  value={this.state.socialUserName5}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialLink5"
                                  type="text"
                                  value={this.state.socialLink5}
                                  onChange={this.onChange.bind(this)}
                                />
                              </>
                            }
                          />
                          <MDBInputGroup
                            inputs={
                              <>
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialType6"
                                  type="text"
                                  value={this.state.socialType6}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialUserName6"
                                  type="text"
                                  value={this.state.socialUserName6}
                                  onChange={this.onChange.bind(this)}
                                />
                                <MDBInput
                                  onBlur={this.handleSubmit.bind(this)}
                                  id="form-text"
                                  noTag
                                  name="socialLink6"
                                  type="text"
                                  value={this.state.socialLink6}
                                  onChange={this.onChange.bind(this)}
                                />
                              </>
                            }
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  {/* {this.props.currentUser.generator === true ? (
                    <Accordion defaultActiveKey="1">
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                          SPOTLIGHT
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <MDBInput
                              onBlur={this.handleSubmit.bind(this)}
                              type="textarea"
                              label="Message"
                              name="message"
                              rows="1"
                              icon="pencil-alt"
                              group
                              value={this.state.message}
                              onChange={this.onChange.bind(this)}
                            />
                            <MDBInput
                              onBlur={this.handleSubmit.bind(this)}
                              label="Image Link"
                              name="imgLink"
                              icon="images"
                              group
                              value={this.state.imgLink}
                              onChange={this.onChange.bind(this)}
                            />
                            <MDBInput
                              onBlur={this.handleSubmit.bind(this)}
                              label="Video *Embed* Link"
                              name="youtubeLink"
                              icon="video"
                              group
                              value={this.state.youtubeLink}
                              onChange={this.onChange.bind(this)}
                            />
                            <br />
                            <MDBInput
                              onBlur={this.handleSubmit.bind(this)}
                              label="html"
                              name="html"
                              type="textarea"
                              rows="4"
                              icon="code"
                              group
                              value={this.state.html}
                              onChange={this.onChange.bind(this)}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  ) : null} */}

                  {/* <Accordion defaultActiveKey="1">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        DIRECT LINK
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <select
                            onBlur={this.handleSubmit.bind(this)}
                            value={this.state.directLink}
                            className="browser-default custom-select"
                            onChange={this.directChangeHandler.bind(this)}
                          >
                            <option
                              value={this.props.landingUrl}
                            >
                              Contact Card (default)
                            </option>

                            <option value={this.props.cardUrl}>VCF</option>

                            {this.state.socialType1 ? (
                              <option value={this.state.socialLink1}>
                                {this.state.socialType1}
                              </option>
                            ) : null}

                            {this.state.socialType2 ? (
                              <option value={this.state.socialLink2}>
                                {this.state.socialType2}
                              </option>
                            ) : null}

                            {this.state.socialType3 ? (
                              <option value={this.state.socialLink3}>
                                {this.state.socialType3}
                              </option>
                            ) : null}

                            {this.state.socialType4 ? (
                              <option value={this.state.socialLink4}>
                                {this.state.socialType4}
                              </option>
                            ) : null}

                            {this.state.socialType5 ? (
                              <option value={this.state.socialLink5}>
                                {this.state.socialType5}
                              </option>
                            ) : null}

                            {this.state.socialType6 ? (
                              <option value={this.state.socialLink6}>
                                {this.state.socialType6}
                              </option>
                            ) : null}

                            <option value="1">Custom</option>
                          </select>

                          {this.state.directLink === "1" ? (
                            <MDBInput
                              onBlur={this.handleSubmit.bind(this)}
                              label="Direct Link"
                              name="customDirectLink"
                              icon="link"
                              group
                              value={this.state.customDirectLink}
                              onChange={this.onChange.bind(this)}
                            />
                          ) : null}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion> */}

                  {this.props.currentUser.generator === true ? (
                    <Accordion defaultActiveKey="1">
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                          GENERATE QR
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            {this.state.inputFocus === true ? (
                              <MDBInput
                                label={"Link"}
                                name="generateQrLink"
                                icon="link"
                                onBlur={this.inputFocusHandler}
                                group
                                value={this.state.generateQrLink}
                                onChange={this.onChange.bind(this)}
                              />
                            ) : (
                              <MDBInput
                                label={"Link"}
                                icon="link"
                                value={""}
                                onFocus={this.inputFocusHandler}
                              />
                            )}
                            <ReactQr
                              text={this.state.generateQrLink}
                              correctLevel={3}
                              size={400}
                              dotScale={0.7}
                              bgSrc={null}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  ) : null}
                </div>
                <div className="text-center py-0 mt-2">
             

                  {/* <MDBBtn
                    className="btn unique-color-dark white-text pushBtn"
                    type="submit"
                    color="unique-color-dark"
                    // onClick={this.btnClick.bind(this) }
                  >
                    <MDBIcon far icon="save" /> {this.state.saveBtn}
                  </MDBBtn> */}

         

                  <MDBBtn
                    className="btn unique-color-dark white-text pushBtn"
                    color="unique-color-dark"
                    onClick={this.preview}
                    target="iframe1"
                  >
                    <MDBIcon far icon="address-card" /> {this.state.saveBtn}
                  </MDBBtn>
                  

                  <MDBModal isOpen={this.state.modal2} toggle={this.toggle2}>
                    <MDBModalHeader
                      tag="p"
                      toggle={this.toggle2}
                    ></MDBModalHeader>
                    <MDBModalBody style={{ height: "80vh", padding: "0" }}>
                      <iframe
                        name="iframe1"
                        title="frame"
                        style={{
                          border: "none",
                          width: "100%",
                          height: "100%",
                          overflow: "hidden"
                        }}
                        src={this.props.landingUrl}
                      ></iframe>
                    </MDBModalBody>
                  </MDBModal>
                </div>

           
              </form>
            </MDBCardBody>
          </MDBCol>
          
        </MDBRow>
      </MDBContainer>
    );
  }
}


const mapStateToProps = ({ user }) => ({
  // user.currentUser && {
  googleUserId: user?.currentUser?.uid,
  croppedImg: user?.currentUser?.croppedImg,
  croppedImg2: user?.currentUser?.croppedImg2,
  croppedImg3: user?.currentUser?.croppedImg3,
  croppedImg4: user?.currentUser?.croppedImg4,
  formData: user?.currentUser?.formData,
  formData2: user?.currentUser?.formData2,
  formData3: user?.currentUser?.formData3,
  formData4: user?.currentUser?.formData4,
  profile: user?.currentUser?.profile,
  userId: user?.currentUser?.id,
  QrImg: user?.currentUser?.QrImg,
  cardUrl: user?.currentUser?.cardUrl,
  landingUrl: user?.currentUser?.landingUrl,
  directLinksUrl: user?.currentUser?.directLinksUrl,
  premium: user?.currentUser?.premium,
  shortUrl: user?.currentUser?.shortUrl,
  currentUser: user?.currentUser,
  MyCardDirectURL: user?.currentUser?.MyCardDirectURL
});
// };

export default withRouter(
  connect(mapStateToProps, {
    updateUser,
    fetchDesigns
  })(FormPage)
);
